
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import InformationDDC from "./InformationDDC.vue";
import formatUtil from "../../mixins/roundValues";
import highlightReports from "../../mixins/highlightReports";
import { formatFinancialAmounts, formatDecimal } from "../../utils/format";

export default Vue.extend({
  name: "Treatment",
  components: { InformationDDC },
  mixins: [formatUtil, highlightReports],
  data: () => ({
    data: [],
    isLoading: false,
    payload: {
      year: null,
    },
  }),
  computed: {
    title() {
      return i18n.t("reports.ddcreports.treatmentAllData.title");
    },
    headers() {
      return [
        {
          text: "N",
          value: "n",
        },
        {
          text: i18n.t("reports.ddcreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("basic.region"),
          value: "region",
        },
        {
          text: i18n.t("basic.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.ddcreports.treatmentAllData.gni"),
          value: "gni",
        },
        {
          text: i18n.t("reports.ddcreports.treatmentAllData.dataYear"),
          value: "dataYear"
        },
        {
          text: i18n.t("reports.ddcreports.treatmentAllData.FC_F8_total_IU"),
          value: "factorVIII_FactorConcentrates_TotalIU",
          formatter: (val: number): number | string =>
              formatFinancialAmounts(val),
        },
        {
          text:  i18n.t("reports.ddcreports.treatmentAllData.FC_F8_total_Unk"),
          value: "factorVIII_FactorConcentrates_IsNotKnown",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },
        {
          text:  i18n.t("reports.ddcreports.treatmentAllData.FVIIIPerCapita"),
          value: "factorVIIIPerCapita",
          formatter: (val: number): number | string => formatDecimal(val, 6),
        },
        {
          text: i18n.t("reports.ddcreports.treatmentAllData.PD_F8_total_IU"),
          value: "factorVIII_PlasmaDerivedConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.RC_F8_total_IU"),
          value: "factorVIII_RecombinantConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.RC_F8_total_Unk"),
          value: "factorVIII_RecombinantConcentrates_IsNotKnown"
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.RC_HL_F8_total_IU"),
          value: "factorVIII_RecombinantExtendedHalfLifeConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.RC_HL_F8_total_Unk"),
          value: "factorVIII_RecombinantExtendedHalfLifeConcentrates_IsNotKnown",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.F8_ND"),
          value: "factorVIII_IsUnableToReport",
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.HA_F8_total_IU"),
          value: "factorVIII_DonatedFactorConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.HA_F8_total_Unk"),
          value: "factorVIII_DonatedFactorConcentrates_IsNotKnown"
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.FC_F9_total_IU"),
          value: "factorIX_FactorConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.FC_F9_total_Unk"),
          value: "factorIX_FactorConcentrates_IsNotKnown",
        },
        {
          text:  i18n.t("reports.ddcreports.treatmentAllData.FIXPerCapita"),
          value: "factorIXPerCapita",
          formatter: (val: number): number | string => formatDecimal(val, 6),
        },
        {
          text:  i18n.t("reports.ddcreports.treatmentAllData.PD_F9_total_IU"),
          value: "factorIX_PlasmaDerivedConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.PD_F9_total_Unk"),
          value: "factorIX_PlasmaDerivedConcentrates_IsNotKnown",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.RC_F9_total_IU"),
          value: "factorIX_RecombinantConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.RC_F9_total_Unk"),
          value: "factorIX_RecombinantConcentrates_IsNotKnown",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.RC_HL_F9_total_IU"),
          value: "factorIX_RecombinantExtendedHalfLifeConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.RC_HL_F9_total_Unk"),
          value: "factorIX_RecombinantExtendedHalfLifeConcentrates_IsNotKnown"
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.F9_ND"),
          value: "factorIX_IsUnableToReport",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.HA_F9_total_IU"),
          value: "factorIX_DonatedFactorConcentrates_TotalIU",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.HA_F9_total_Unk"),
          value: "factorIX_DonatedFactorConcentrates_IsNotKnown"
        },{
          text:  i18n.t("reports.ddcreports.treatmentAllData.Hemlibra_mg_IU"),
          value: "purchasedHemlibra_TotalMg",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text: i18n.t("reports.ddcreports.treatmentAllData.Hemlibra_mg_unk_IU"),
          value: "purchasedHemlibra_IsNotKnown"
        },{
          text: i18n.t("reports.ddcreports.treatmentAllData.Population"),
          value: "population",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text: i18n.t("reports.ddcreports.treatmentAllData.Accuracy_Factor"),
          value: "factorUseIsInformationAccurate"
        },{
          text: i18n.t("reports.ddcreports.treatmentAllData.Comment"),
          value: "factorUseComment",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },{
          text: i18n.t("reports.ddcreports.treatmentAllData.Discrepancies"),
          value: "factorUseDiscrepancies",
          formatter: (val: number): number | string => formatFinancialAmounts(val),
        },

      ];
    },
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.payload.year = this.yearSelected;
      axios
        .get("report/DDC/FactorUse-AllData", {
          params: { year: this.yearSelected },
        })
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(`report/ddc/FactorUse-AllData/export`, {
          params: { year: this.yearSelected },
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.yearSelected) {
      this.getData();
    }
  },
  watch: {
    yearSelected() {
      this.getData();
    },
  },
});
